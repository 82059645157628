<!--
 * @Author: wjc
 * @Date: 2023-11-10 16:01:22
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-09-04 11:17:15
 * @Description: 
-->
<template>
  <div class="footer relative bg-bg-4 text-color-whiter font-s-1.4rem py-20px lt-xs:font-s-1.6rem">
    <MRow
      align="middle"
      justify="space-between"
      :span="span"
      :gutter="$screen.higherThan('xs') ? 20 : null"
    >
      <el-col :md="18" :xs="24">
        <el-space
          size="large"
          class="links pb-20px mb-20px"
          :alignment="$screen.higherThan('sm') ? 'flex-start' : 'center'"
          :direction="$screen.higherThan('sm') ? 'vertical' : 'horizontal'"
        >
          <div class="w-full font-s-1.6rem text-left">友情链接</div>
          <el-select placeholder="请选择" class="link-select">
            <el-option value="1">
              <MLink to="https://hkjsj.haikou.gov.cn/" target="_blank">
                海口市住房和城乡建设局
              </MLink>
            </el-option>
          </el-select>
        </el-space>
        <div class="flex justify-evenly font-s-1.4rem lt-xs:font-s-1.6rem">
          <el-row class="flex-1">
            <el-col :md="10" :sm="24" :xs="24" class="md:my-0px lt-xs:my-24px">
              <div class="md:mb-16px lt-xs:mb-8px">主办单位：海口市物业管理行业协会</div>
              <div>技术支持：海南智慧城科技有限公司</div>
            </el-col>
            <el-col :md="6" :sm="24" :xs="24">
              <div class="mb-16px lt-xs:mb-8px">联系电话：089866284683</div>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :md="6" :xs="24" class="wc-qrcode">
        <div class="text-center bg-#2e473e p-8px font-s-14px">
          <img src="/images/qrcode.jpg" class="h-98px w-full" />
          <div class="mt-4px text-overflow">
            <!-- <div>物业协会</div> -->
            <div>微信公众号</div>
          </div>
        </div>
        <div class="text-center bg-#2e473e p-8px font-s-14px">
          <img src="/images/index.jpeg" class="h-98px w-full" />
          <div class="mt-4px text-overflow">
            <!-- <div>物业协会</div> -->
            <div>微信视频号</div>
          </div>
        </div>
      </el-col>
    </MRow>
    <div class="border-t-1px border-t-solid border-t-white/10 mt-20px">
      <MRow :span="span" :gutter="$screen.higherThan('xs') ? 20 : null">
        <el-col :xs="24" :md="12" class="font-s-1.4rem text-color-3 pt-20px lt-xs:font-s-1.6rem">
          <el-row>
            <el-col :md="4" :xs="24">© 2012-{{ new Date().getFullYear() }}</el-col>
            <el-col :md="8" :xs="24">{{ config.footerWrite.url }}</el-col>
            <el-col :md="8" :xs="24">{{ appName }}</el-col>
          </el-row>
        </el-col>
        <el-col
          :xs="24"
          :md="12"
          class="font-s-1.4rem text-color-3 pt-20px lt-xs:font-s-1.6rem lt-xs:text-left md:text-right"
        >
          <MLink to="https://beian.miit.gov.cn" target="_blank">琼ICP备2023015601号-1</MLink>
        </el-col>
      </MRow>
    </div>
  </div>
</template>

<script setup lang="ts">
  import config, { appName } from '~/constants'
  defineOptions({
    name: 'Footer',
  })

  const $screen = useScreen()
  const span = computed(() => $screen.span.value)
</script>

<style scoped lang="scss">
  .links {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .wc-qrcode {
    @apply md:justify-end;
    display: grid;
    grid-template-columns: repeat(2, 108px);
    gap: 24px;
  }
</style>
